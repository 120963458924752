/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <link rel="stylesheet" href="/css?family=''&family=Alegreya:wght@400;700;900&family=Archivo:wght@300;400;700;900&family=Arvo:wght@400;700&family=B612:wght@400;700&family=Belleza&family=BioRhyme:wght@300;400;700&family=Cairo:wght@300;400;700;900&family=Cardo:wght@400;700&family=Concert+One&family=Cormorant:wght@300;400;700&family=Crimson+Text:wght@400;700&family=Fira+Sans:wght@300;400;700;900&family=Fjalla+One&family=Frank+Ruhl+Libre:wght@300;400;700;900&family=Great+Vibes&family=Karla:wght@300;400;700&family=Krona+One&family=Lato:wght@300;400;700;900&family=Lora:wght@400;700&family=Merriweather:wght@300;400;700;900&family=Montserrat:wght@300;400;700;900&family=Noto+Sans:wght@300;400;700;900&family=Nunito:wght@300;400;700;900&family=Old+Standard+TT:wght@400;700&family=Open+Sans:wght@300;400;700&family=Oswald:wght@300;400;700&family=Oxygen:wght@300;400;700&family=PT+Sans:wght@400;700&family=PT+Serif:wght@400;700&family=Parisienne&family=Pinyon+Script&family=Playfair+Display:wght@400;700;900&family=Poppins:wght@300;400;700;900&family=Rakkas&family=Roboto:wght@300;400;700;900&family=Rubik:wght@300;400;700;900&family=Source+Sans+Pro:wght@300;400;700;900&family=Space+Grotesk:wght@300;400;700&family=Spectral:wght@300;400;700&family=Syne:wght@400;700&family=Tangerine:wght@400;700&family=Titillium+Web:wght@300;400;700;900&family=Ubuntu:wght@300;400;700&family=Varela&family=Vollkorn:wght@400;700;900&family=Work+Sans:wght@300;400;700;900&family=Yatra+One&display=swap"></link>
      {children}
    </>
  )
}

export default Seo
